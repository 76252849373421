<template>
    <div class="row">
        <div class="col-12 col-md-4">
            <img :src="require('../assets/1.jpg')" class="img-fluid img-thumbnail">
        </div>
        
        <div class="col-12 col-md-8 text">
            <h3>Deutschpunkt Liburna 
                <span class="flags">
                <img :src="require('../assets/germany.png')" alt="" v-if="albanian" @click="albanian = false">
                <img :src="require('../assets/albania.png')" alt="" v-if="!albanian" @click="albanian = true">
                </span></h3>
            <p v-if="albanian">Si kërkesë në rritje për përkthime dhe mësimin e gjuhës gjermane startuam në vitin 2004 me ofrimin e <a href="#/kurset">kurseve</a> fillestare A1 dhe A2. Nga viti 2010 ofrohen vazhdimisht edhe nivelet më të larta B1 dhe B2, për tu zgjeruar nga viti 2020 edhe me <a href="#/kurset">kurse</a> për infermier/e B1 dhe B2. <br>Një mundësi tjetër avansimi në pikën tonë arsimore është edhe parapërgatitja speciale për provimet gjuhësore për të gjitha nivelet e lartpërmendura. Së fundmi ofrohen në pikën tonë edhe kurse bashkëbisedimi në dy nivele A (A1+A2) dhe B (B1+B2). <br>Kujdes të veqant i kushtojmë bashkëpunimit dhe angazhimit tonë permanent me institutet e lartëvlerësuara: <a href="https://www.senioreneinrichtungen.info/">Senioreneinrichtungen des Landkreises Würzburg-Gjermani</a>  dhe <a href="https://goethe-ks.org/">Goethe Institutit resp. Goethe Zentrum-Prishtinë</a>. <br>Ne vazhdimisht avansohemi e hulumtojmë  mënyra përparuese për lehtësimin e rrugës suaj në mësimin e gjuhës gjermane.</p>
            <p v-if="!albanian">Wegen der ständig wachsenden Nachfrage an Übersetzungen und dem Erlernen der deutschen Sprache begannen wir 2004,  A1- und A2-Deutschkurse anzubieten.  Seit 2010 bieten wir auch die höheren Niveaukurse B1 und B2 bei uns an, und seit 2020 erweiterten wir unser Angebot mit berufsorientierten Sprachkursen im Bereich Pflege B1 und B2. <br>Ein weiteres Training in unserem Lernpunkt ist auch die spezielle Vorbereitung für Sprachprüfungen für alle oben erwähnten Stufen. Letztlich bieten wir hier auch Konversationskurse in zwei Stufen, A (A1 + A2) und B (B1 + B2) an.<br>  Besonderen Wert schenken wir auch unserer Kooperation und permanentem Engagement für die hochgeschätzten Institute <a href="https://www.senioreneinrichtungen.info/">„Senioreneinrichtungen des Landkreises Würzburg-Deutschland“</a>  und <a href="https://goethe-ks.org/">„Goethe Institut“ bzw. „Goethe Zentrum-Prishtina“</a> .  <br>Wir bilden uns ständig weiter und verwenden fortgeschrittene Methoden mit aktuellen Lehrwerken für die Erleichterung Ihres Weges beim Lernen der deutschen Sprache.  </p>
        </div>
            </div>
</template>

<script>
export default {
    name: 'Entry',
    data() {
        return {
            title: '',
            paragraph_1: 'Si kerkesë në rritje për përkthime dhe mësimin e gjuhës gjermane startuam në vitin 2004 me ofrimin e kurseve fillestare A1 dhe A2. \\nNga viti 2010 ofrohen vazhdimisht edhe nivelet më të larta B1 dhe B2, për tu zgjeruar nga viti 2020 edhe me kurse për infermier/e B1 dhe B2. Një mundësi tjetër avansimi në pikën tonë arsimore është edhe parapërgatitja speciale për provimet gjuhësore për të gjitha nivelet e lartpërmendura. Së fundmi ofrohen në pikën tonë edhe kurse bashkëbisedimi në dy nivele A (A1+A2) dhe B (B1+B2). Kujdes të veqant i kushtojmë bashkëpunimit dhe angazhimit tonë permanent me institutet e lartëvlerësuara: Senioreneinrichtungen des Landkreises Würzburg-Gjermani dhe Goethe Institutit resp. Goethe Zentrum-Prishtinë. Ne vazhdimisht avansohemi e hulumtojmë  mënyra perparuese për lehtësimin gjatë rrugës Suaj në mësimin e gjuhës gjermane.',
            paragraph_2: 'Wegen der ständig wachsenden Nachfrage an Übersetzungen und dem Erlernen der deutschen Sprache begannen wir 2004,  A1- und A2-Deutschkurse anzubieten.  Seit 2010 bieten wir auch die höheren Niveaukurse B1 und B2 bei uns an, und seit 2020 erweiterten wir unser Angebot mit berufsorientierten Sprachkursen im Bereich Pflege B1 und B2. Ein weiteres Training in unserem Lernpunkt ist auch die spezielle Vorbereitung für Sprachprüfungen für alle oben erwähnten Stufen. Letztlich bieten wir hier auch Konversationskurse in zwei Stufen, A (A1 + A2) und B (B1 + B2) an.  Besonderen Wert schenken wir auch unserer Kooperation und permanentem Engagement für die hochgeschätzten Institute „Senioreneinrichtungen des Landkreises Würzburg-Deutschland“ und „Goethe Institut“ bzw. „Goethe Zentrum-Prishtina“.  Wir bilden uns ständig weiter und verwenden fortgeschrittene Methoden mit aktuellen Lehrwerken für die Erleichterung Ihres Weges beim Lernen der deutschen Sprache.  ',
            albanian: true,
        }
    }
}
</script>

<style lang="scss" scoped>
.row {
    padding: 20x;
    margin-right: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    .text {
        padding: 10px !important;
    }
    .flags {
        cursor: pointer;
    }
    img {
        margin: 10px;
    }
    h3 {
        display: flex;
        justify-content: space-between;
    }
     p {
         font-family: Arial, Helvetica, sans-serif;
     }
}
</style>
